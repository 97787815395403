import React from 'react';
import { FaGithub, FaLinkedin } from "react-icons/fa";
import footerStyles from './footer.module.scss';
import Weather from "./Weather";
import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.contents}>
                {/*Weather component*/}
                <Weather/>

                {/*Social networks*/}
                <div className={footerStyles.social}>
                    <a href="https://github.com/CallyRoad" title={"Visiter le github de Coralie"}><FaGithub
                        className={footerStyles["github-social"]}/></a>
                    <a href="https://fr.linkedin.com/in/coralie-venuat-092205236"
                       title={"Visiter le linkedin de Coralie"}><FaLinkedin
                        className={footerStyles["linkedin-social"]}/></a>
                </div>
            </div>

            {/* copyrights */}
            <div className={footerStyles.mentions}>
                <p>&copy; Copyright 2024 - Coralie Venuat | Tous droits réservés. | <Link to={"/privacy-notice"}><strong>En savoir plus</strong></Link></p>
            </div>

        </footer>
    );
};

export default Footer;