import React from 'react';
import stylesProjects from "../../../../pages/projects/projects.module.scss";
import { FiExternalLink } from "react-icons/fi";
import { FaGithub } from "react-icons/fa";
import ProjectTechnologies from "../ProjectTechnologies";


// Component for displaying project maps in tablet or desktop versions
const ProjectsCardsTabOrDesktopVersion = ({ filteredProjects }) => {
    return (
        <>
            {filteredProjects.map((project, index) => (
                <li key={index} className={stylesProjects["project-card"]}>
                    <img
                        src={process.env.PUBLIC_URL + (project.picture || "no-picture-available.png")}
                        alt={project.title}
                        title={project.pictureDescription}
                        loading={"lazy"}
                    />

                    {/* Description superimposed on image for tablet or desktop version */}
                    <div className={stylesProjects["card-overlay"]}>
                        <h3>{project.title}</h3>
                        <p>{project.resume}</p>

                        {/* Links to the site and source code, except the portfolio */}
                        {project.title !== "Porfolio - Coralie Venuat" && (
                            project.link && (
                                    <a href={project.link} target="_blank" title="Visiter le site">
                                        <FiExternalLink />
                                    </a>
                            )
                        )}

                        {project.linkCode && (
                            <a href={project.linkCode} target="_blank"  title="Accéder au code" className={stylesProjects["github-link"]}>
                                <FaGithub />
                            </a>
                        )}

                        {/* Project status (Completed or In progress) */}
                        <span
                            className={`${stylesProjects["project-status"]} ${project.status === "Completed" ? stylesProjects["project-status-completed"] : stylesProjects["project-status-in-progress"]}`}
                            title={project.status === "Completed" ? "Terminé" : "En cours"}
                        ></span>

                        {/* ProjectTechnologies component */}
                        <ProjectTechnologies technologies={project.technologies}/>
                    </div>
                </li>
            ))}
        </>
    );
};

export default ProjectsCardsTabOrDesktopVersion;
