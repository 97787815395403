import React from "react";
import About from "./pages/about/About";
import Layout from "./components/layout/Layout"
function App() {

  return (
    <Layout>
        <About/>
    </Layout>
  );
}

export default App;
