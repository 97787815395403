import React from 'react';
import stylesProjects from "../../../../pages/projects/projects.module.scss";
import {FiExternalLink} from "react-icons/fi";
import {FaGithub} from "react-icons/fa";
import ProjectTechnologies from "../ProjectTechnologies";

// Component for displaying project maps in mobile version
const ProjectsCardsMobileVersion = ({filteredProjects}) => {
    return (
        <>
            {filteredProjects.map((project, index) => (
                <>
                    <li key={index + "-mobile"} className={stylesProjects["project-card"]}>
                        {/* Project image */}
                        <img
                            src={process.env.PUBLIC_URL + (project.picture || "no-picture-available.png")}
                            alt={project.title}
                            title={project.pictureDescription}
                            loading={"lazy"}
                        />
                    </li>

                    {/*Description in a box under the project image for mobile versions */}
                    <div className={stylesProjects["card-description"]}>
                        <h3>{project.title} <small>({project.createdAt})</small></h3>
                        <p>{project.resume}</p>

                        <span
                            className={`${stylesProjects["project-status"]} ${project.status === "Completed" ? stylesProjects["project-status-completed"] : stylesProjects["project-status-in-progress"]}`}
                            title={project.status === "Completed" ? "Terminé" : "En cours"}
                        ></span>

                        {/*ProjectTechnologies component*/}
                        <ProjectTechnologies technologies={project.technologies}/>

                        {/* Links to the site and source code, except the portfolio */}
                        <div className={stylesProjects["links-container"]}>
                            {project.title !== "Porfolio - Coralie Venuat" && (
                                project.link && (
                                    <a href={project.link} target="_blank" title="Visiter le site">
                                        <FiExternalLink/>
                                    </a>
                                )
                            )}

                            {project.linkCode && (
                                <a href={project.linkCode} target="_blank"  title="Accéder au code"
                                   className={stylesProjects["github-link"]}>
                                    <FaGithub/>
                                </a>
                            )}
                        </div>
                    </div>
                    <span className={stylesProjects["project-separator"]}></span>
                </>
            ))}
        </>
    );
};

export default ProjectsCardsMobileVersion;