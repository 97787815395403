import React from 'react';
import {Helmet} from "react-helmet";
import {FaHtml5, FaCss3, FaSass, FaReact, FaJs, FaGit, FaPython, FaFigma} from 'react-icons/fa';
import { RiNextjsFill } from "react-icons/ri";
// component import
import TypewriterEffect from "../../components/main/TypewriterEffect";
//styles import
import stylesAbout from "./about.module.scss";
/* CV and pictures imports */
import FoxUpload from "../../assets/fox_upload.png";
import FoxExtend from "../../assets/fox_extend.png";
import CV from "../../assets/cv/CV_VENUAT_CORALIE.pdf";

const About = () => {
    return (
        <>
            {/* Helmet for metadata */}
            <Helmet>
                <title>À propos de Coralie Venuat - Développeuse Web Junior</title>
                <meta name="description" content="Découvrez le parcours de Coralie Venuat, développeuse web junior, spécialisée en front-end avec une formation en développement web et des compétences en React.js, Python, et plus encore." />
                <meta name="keywords" content="Coralie Venuat, développeuse web, front-end, React.js, Python, HTML5, CSS3, 3W Academy" />
            </Helmet>

            {/* TypewriterEffect component */}
            <TypewriterEffect text={"Coralie Venuat Développeuse Web Junior"}/>

            {/* Section home page */}
            <section className={stylesAbout["home-page"]}>

                <article className={stylesAbout.about}>
                    <p>
                        Après avoir travaillé pendant presque <span>7 ans en restauration rapide</span>, j'ai décidé de me
                        reconvertir dans le domaine passionnant du <span>développement web</span>. Quelques mois durant, j'ai pris l'initiative d'apprendre les bases de <span>Python</span> en autodidacte, ce
                        qui m'a permis de faire mes premiers pas dans le monde de la programmation.
                    </p>

                    <p>
                        J'ai entrepris une formation intensive chez la <span>3W Academy</span>, où
                        j'ai obtenu en mai 2023 le diplôme de <span>Développeur intégrateur en réalisation d’applications web</span>.
                        Depuis la fin de ma formation, je continue à perfectionner mes compétences de manière autonome et je
                        suis actuellement à la recherche d'une opportunité professionnelle.
                    </p>

                    <p>
                        Je suis particulièrement intéressée par un poste de <span>Développeur front-end</span>, mais je suis
                        également ouverte à des opportunités en <span>back-end</span>.
                    </p>

                    <p>
                        Je me considère comme une personne <span>curieuse</span> et <span>assidue</span>, avec un <span>besoin constant d'apprendre</span>.
                        Ces qualités me poussent à toujours chercher à améliorer mes compétences et à rester à jour avec les
                        dernières technologies du web.
                    </p>
                </article>

                {/* Skills block */}
                <article className={stylesAbout.skills}>
                    <div className={stylesAbout["list-skills"]}>
                        <p>
                            <FaHtml5/>HTML5
                        </p>
                        <p>
                            <FaCss3/>CSS
                        </p>
                        <p>
                            <FaJs/>JavaScript
                        </p>
                        <p>
                            <FaReact/>React.js
                        </p>
                        <p>
                            <FaGit/>Git
                        </p>
                        <p>
                            <FaSass/>Sass
                        </p>
                        <p>
                            <FaFigma/>Figma
                        </p>
                        <p>
                            <RiNextjsFill/>Next.js
                        </p>
                        <p>
                            <FaPython/>Python
                        </p>
                    </div>

                    {/* Upload container */}
                    <div className={stylesAbout["upload-container"]}>
                       <p>
                           <img src={FoxUpload} alt="Télécharger le CV" title="Télécharger le CV"/>
                           <a href={CV} download className={stylesAbout["cv-download"]}>
                               Télécharger mon CV
                           </a>
                       </p>

                       <p>
                           <img src={FoxExtend} alt="Agrandir le CV" title="Agrandir le CV"/>
                            <a href={CV} target="_blank" rel="noopener noreferrer" className={stylesAbout["cv-view"]}>
                                Voir mon CV
                            </a>
                       </p>
                    </div>
                </article>
            </section>
        </>
    );
};

export default About;