import React, {useRef, useState} from 'react';
import stylesContact from '../../../pages/contact/contact.module.scss';
import {validateForm} from "../../../utils/validateForm";
import {URL_SERVER} from "../../../utils/env";
//--Import emailjs
import emailjs from "@emailjs/browser";
//--Import ReCaptcha
import ReCAPTCHA from "react-google-recaptcha";
//--Imports react-toastify
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//--Import react-responsive
import {useMediaQuery} from "react-responsive";

const ContactForm = () => {
    const [formState, setFormState] = useState({
        name : "",
        email : "",
        message : "",
        honeypot: ""
    });

    const recaptchaRef = useRef();

    const [isSubmitting, setIsSubmitting] = useState(false);

    // Hook to determine if the device is mobile to adjust the size of the recatpcha
    const isMobile = useMediaQuery({
        query : '(max-width: 764px)'
    });

    // 5 minutes delay
    const timeLimitForResendingMessage = 5;

    // Function to handle form field changes
    const handleChangeForm = (e) => {
        const {name, value} = e.target;
        setFormState({ ...formState, [name]: value });
    };

    // Function to reset the form and reCAPTCHA after submission
    const resetForm = () => {
        setFormState({
            name: "",
            email: "",
            message: "",
            honeypot: ""
        });
        recaptchaRef.current.reset();
    };

    // Function to prevent spam messages from the current user

    const canSubmitEmail = () => {
      const lastSubmitTime = localStorage.getItem("lastSubmitTime");
      const currentTime = new Date().getTime();

        if (lastSubmitTime) {
            //Time passed convert to minutes
            const timePassed = (currentTime - lastSubmitTime) / 1000 / 60;

            if (timePassed < timeLimitForResendingMessage) {
                const remainingMinutes = timeLimitForResendingMessage - timePassed;
                toast.error(`Veuillez attendre ${remainingMinutes.toFixed(1)} minutes avant de réessayer.`)
                return false;
            }
        }

        return true;
    };

    // Function to handle form submission
    const handleSubmitForm = async (e) => {
        e.preventDefault();

        // Prevent form submission if delay not met
        if (!canSubmitEmail()) return;

        // Honeypot check
        if (formState.honeypot) {
            console.warn("Bot detected :( - honeypot filled");
            return;
        }

        // Validate the form fields
        const validationError = validateForm(formState);

        if (validationError) {
            toast.error(validationError);
            return;
        }

        // handler error if captcha value isn't valid
        const captchaValue = recaptchaRef.current?.getValue();

        if (!captchaValue) {
            toast.error("Vérifier le captcha");
            return;
        }

        setIsSubmitting(true);

        try {
            // Verify reCAPTCHA with the server
            const response = await fetch(`${URL_SERVER}/verify-recaptcha`, {
                method: 'POST',
                headers: {
                        'Content-Type': 'application/json',
                },
                body: JSON.stringify({ captchaValue }),
            });

            const data = await response.json();

            if (data.success) {
                // Send email if reCAPTCHA is verified successfully
                await emailjs.send(
                    process.env.REACT_APP_SERVICE_ID_EMAILJS,
                    process.env.REACT_APP_TEMPLATE_ID_EMAILJS,
                    {
                        from_name: formState.name,
                        message: formState.message,
                        reply_to: formState.email,
                    },
                   process.env.REACT_APP_PUBLIC_KEY_EMAILJS
               );

                // Set last submit time to current time
                localStorage.setItem("lastSubmitTime", new Date().getTime());

                toast.success("Message envoyé avec succès");

                // Reset the form and reCAPTCHA after successful submission
                resetForm();
            } else {
                toast.error(data.message || "Echec de la vérification du captcha");
            }

        } catch (error) {
            toast.error("Erreur lors de la vérification du captcha");
            console.error("Error:", error);

        }  finally {
            // Re-enable form submission
            setIsSubmitting(false);
        }
    };

    return (
        <form className={stylesContact["contact-form"]} onSubmit={handleSubmitForm} aria-label={"Contacter Coralie Venuat par email"}>
            {/* Honeypot field, hidden from users and prevents tabbing into this field */}
            <input
                type="text"
                name="honeypot"
                value={formState.honeypot}
                onChange={handleChangeForm}
                style={{ display: 'none' }}
                tabIndex="-1"
                autoComplete="off"
            />
            {/* --- End of Honeypot --- */}

            {/*Name field*/}
            <div className={stylesContact["form-group"]}>
                <label htmlFor="name">Nom</label>
                <input id="name" name="name" placeholder={"Nom"} title={"Renseignez votre nom"} value={formState.name} onChange={handleChangeForm} required/>
            </div>

            {/*Email field*/}
            <div className={stylesContact["form-group"]}>
                <label htmlFor="email">Email</label>
                <input id="email" name="email" placeholder="email@example.com" title="Renseignez votre email au format email@example.com" value={formState.email} onChange={handleChangeForm} required/>
            </div>

            {/*Message field*/}
            <div className={stylesContact["form-group"]}>
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" placeholder="Message" title={"Renseignez votre message"} value={formState.message} onChange={handleChangeForm} required/>
            </div>

            {/*ReCaptcha*/}
            <div className={stylesContact["form-group"]} >
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={recaptchaRef}
                    theme={"dark"}
                    size={isMobile ? "compact" : "normal"}
                />
            </div>

            {/*Toast notification*/}
            <ToastContainer position="top-center" theme={"dark"} autoClose={5000} hideProgressBar={false} closeOnClick pauseOnHover draggable />

            {/*Send form*/}
            <button type="submit" aria-label="envoyer" disabled={isSubmitting}>
                {isSubmitting ? "Envoi en cours..." : "Envoyer"}
            </button>
        </form>
    );
};

export default ContactForm;