import React from 'react';
import privacyStyles from "./privacyNotice.module.scss";
import { PiCookieDuotone } from "react-icons/pi";
import {Link} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {useLocation} from "react-router-dom";

const PrivacyNotice = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={location.pathname}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={privacyStyles.notice}>

                <PiCookieDuotone className={privacyStyles.cookies}/>

                <h2>Protection des Données Personnelles et Utilisation des Cookies </h2>

                <p>
                    Lorsque vous utilisez le formulaire de contact sur ce site, vos informations personnelles
                    (nom, adresse e-mail, message) sont collectées uniquement pour vous recontacter.
                    Aucune de vos données n'est stockée ni transmise à des tiers.
                </p>

                <p>
                    Ce site utilise des cookies strictement nécessaires pour assurer la sécurité des
                    services fournis, notamment via Google reCAPTCHA. Vous pouvez configurer votre
                    navigateur pour gérer les cookies, mais cela pourrait affecter certaines fonctionnalités du site.
                </p>

                <p>
                    Pour toute question concernant vos données, veuillez me contacter via le <Link to={"/contact"} aria-label={"Accéder au formulaire de contact"} title={"Accéder au formulaire de contact"}>formulaire de contact</Link>.
                </p>


                <Link to={"/"} aria-label={"Retour à la page d'accueil"} title={"Retour à la page d'accueil"}>
                    <strong>
                            Retourner sur la page d'accueil
                    </strong>
                </Link>

            </motion.div>
        </AnimatePresence>
    );
};

export default PrivacyNotice;