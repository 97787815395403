import React from "react";
import errors from "./errors.module.scss";
import {Helmet} from "react-helmet";
import {AnimatePresence, motion} from "framer-motion";
import Loading from "../loading-element/Loading";

const NotFound = () => {
    return (
        <>
            {/*  Helmet */}
            <Helmet>
                <title>Page introuvable | Portfolio VENUAT Coralie</title>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>

            <AnimatePresence>
                <motion.main
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    key="not-found"
                    className={errors.container}>
                    <h1>Erreur 404 - Page non trouvée</h1>
                    <p>Désolé, la page que vous recherchez est introuvable.</p>
                        <a
                            href="/"
                            className={errors.link}
                            title={`Retour à la page d'accueil`}
                            alt="Retourner à la page d'accueil"
                        >
                            Retourner à la page d'accueil
                        </a>
                </motion.main>
            </AnimatePresence>
        </>
    );
};

export default NotFound;
