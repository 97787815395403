import {regExEmail} from "./regExEmail";

// This function checks that the data provided in the form is correct.
// - Each field must not be empty
// - Name must be at least 3 characters long
// - Message must be at least 10 characters long
// - Email must be a valid email address
// This function is tested in validateForm.test.js

export const validateForm = (formState) => {
    if (!formState.name || formState.name.length < 3) {
        return 'Le nom est requis et doit comporter au moins 3 caractères';
    }
    if (!formState.email || !regExEmail.test(formState.email)) {
        return 'Un email valide est requis';
    }
    if (!formState.message || formState.message.length < 10) {
        return 'Le message est requis et doit comporter au moins 10 caractères';
    }
    return '';
};