import React from 'react';
import {Helmet} from "react-helmet";
import {AnimatePresence, motion} from "framer-motion";
import errors from "./errors.module.scss";

const InternalServerError = () => {
    return (
        <>
            {/* Helmet */}
            <Helmet>
                <title>Page introuvable | Portfolio VENUAT Coralie</title>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>

            <AnimatePresence>
                <motion.main
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    key="not-found"
                    className={errors.container}>
                    <h1>Erreur Interne du Serveur</h1>
                    <p>Désolé, quelque chose s'est mal passé. Veuillez réessayer plus tard.</p>
                        <a href="/"
                           className={errors.link}
                           title={`Retour à la page d'accueil`}
                           alt="Retourner à la page d'accueil"
                        >
                            Retourner à l'accueil
                        </a>
                </motion.main>
            </AnimatePresence>
        </>
    );
};

export default InternalServerError;