import React  from 'react';
import {NavLink} from "react-router-dom";
import headerStyles from "./header.module.scss";
import Logo from "../../assets/callyroad_logo.png";
const Header = () => {

    return (
        <header className={headerStyles.header}>
            {/* Navigation bar */}
            <nav className={headerStyles.navigation} role={"navigation"}>
                <a href="/">
                    <img src={Logo} className={headerStyles.logo} alt="Logo de Coralie Venuat" title={"↝ Disponible à l'embauche"}/>
                </a>

                {/* List of pages */}
                <ul>
                    <li className={`${headerStyles["nav-list"]}`}>
                        <NavLink to={"/"} className={({isActive}) =>
                            isActive ? `${headerStyles["nav-link"]} ${headerStyles["active-link"]}` : headerStyles["nav-link"]
                        }
                        aria-label={"Se rendre sur la page d'accueil"} title={"Page d'accueil"}
                        >
                            À propos
                        </NavLink>
                    </li>

                    <li className={headerStyles["nav-list"]}>
                        <NavLink to={"/projects"} className={({isActive}) =>
                            isActive ? `${headerStyles["nav-link"]} ${headerStyles["active-link"]}` : headerStyles["nav-link"]
                        }
                        aria-label={"Se rendre sur la page des projets"} title={"Page des projets"}
                        >
                            Projets
                        </NavLink>
                    </li>

                    <li className={headerStyles["nav-list"]}>
                        <NavLink to={"/contact"} className={({isActive}) =>
                            isActive ? `${headerStyles["nav-link"]} ${headerStyles["active-link"]}` : headerStyles["nav-link"]
                        }
                        aria-label={"Se rendre sur la page de contact"} title={"Page de contact"}
                        >
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;