import React, {useState} from 'react';
import stylesProjects from "./projects.module.scss";
import Layout from "../../components/layout/Layout";
import ProjectsCards from "../../components/main/projects/ProjectsCards";
import TypewriterEffect from "../../components/main/TypewriterEffect";
import projects from "../../data/projects.json";
import {Helmet} from "react-helmet";
const Projects = () => {
    const [selectedYear, setSelectedYear] = useState(null);

    // We extract the existing dates in our projects from the “projects” json file.
    const years = [...new Set(projects.map(project => new Date(project.createdAt).getFullYear()))];

    const filteredProjects = selectedYear ? projects.filter(project => new Date(project.createdAt).getFullYear() === selectedYear) : projects;

    return (
        <Layout>
            {/* Helmet for metadata */}
            <Helmet>
                <title>Projets de Coralie Venuat - Développeuse Web Junior</title>
                <meta name="description" content="Découvrez les projets réalisés par Coralie Venuat, développeuse web junior, incluant des projets en front-end, back-end, et plus encore." />
                <meta name="keywords" content="Projets Coralie Venuat, développement web, front-end, back-end, portfolio" />
            </Helmet>

            {/* TypewriterEffect component */}
            <TypewriterEffect text={"Mes projets"}/>

            <section>
                <div className={stylesProjects["filter-buttons"]}>

                    {/* Buttons for each existing year */}
                    {years.map((year, index) => (
                        <button
                            key={index}
                            className={selectedYear === year ? stylesProjects["tech-item-active"] : stylesProjects["tech-item"]}
                            onClick={() => setSelectedYear(year)}
                        >
                            {year}
                        </button>
                    ))}

                    <button
                        className={!selectedYear ? stylesProjects["tech-item-active"] : stylesProjects["tech-item"]}
                        onClick={() => setSelectedYear(null)}
                    >
                        Tous
                    </button>
                </div>
                {/* ProjectsCards component */}
                <ProjectsCards filteredProjects={filteredProjects}/>
            </section>
        </Layout>
    );
};

export default Projects;