import React, {useEffect, useState} from 'react';
const TypewriterEffect = ({text}) => {
    const [displayText, setDisplayText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationFinished, setAnimationFinished] = useState(false);

    // const text = "Coralie Venuat Developpeuse Web Junior ";


    useEffect(() => {
        const typingEffect = setInterval(() => {
            if (currentIndex < text.length) {
                setDisplayText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }
            else {
                clearInterval(typingEffect);
                setAnimationFinished(true);
            }

        }, 100);
        return () => clearInterval(typingEffect);
    }, [currentIndex, text ]);

    return (
        <div className="typewriter">
            <h1>{'\u003C'}{displayText}{animationFinished && <>{'\u002F'}{'\u003E'}</>}</h1>
        </div>

    );
};

export default TypewriterEffect;