import React, {useEffect, useState} from 'react';
import {API_KEY_WEATHER} from "../../utils/env";
import {weatherUrl} from "../../api/url";
import footerStyles from "./footer.module.scss";
const Weather = () => {
    const [weatherData, setWeatherData] = useState(null);
    const initialCity = "Bordeaux";


    useEffect(() => {
        fetch(weatherUrl+`/v1/current.json?key=${API_KEY_WEATHER}&q=${initialCity}&lang=fr`)
            .then(response => response.json())
            .then(data => {
                setWeatherData(data)
            })
            .catch(error => console.error("Erreur lors de la récupération des données météorologiques.", error));
    }, []);
    return (
        <>
            {weatherData && (
                <div className={footerStyles["weather-container"]}>
                    <p className={footerStyles.temp}>{weatherData.current.temp_c} C° à {initialCity}</p>
                    <span className={footerStyles["weather-tooltip"]} aria-label={`${weatherData.current.condition.text}`}>
                        <img
                            className={footerStyles["weather-icon"]}
                            loading={"lazy"}
                            src={weatherData.current.condition.icon}
                            alt={`Icône de condition météorologique (provenant de WeatherAPI) : ${weatherData.current.condition.text}`}
                            title={`Condition actuelle sur Bordeaux : ${weatherData.current.condition.text}`}
                        />
                    </span>
                    <p>Indice UV : {weatherData.current.uv}</p>
                </div>
            )}
        </>
    );
};

export default Weather;