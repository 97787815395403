import React from 'react';
import stylesProjects from "../../../pages/projects/projects.module.scss";

// Component to display the technologies used in a project
const ProjectTechnologies = ({technologies}) => {
    return (
        <div className={stylesProjects["technologies"]}>
            {technologies.map((technology, techIndex) => (
                <span key={techIndex} className={stylesProjects["tech-item"]}>{technology}</span>
            ))}
        </div>
    );
};

export default ProjectTechnologies;