import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
//components import
import App from './App';
import Projects from "./pages/projects/Projects";
import Contact from "./pages/contact/Contact";
import PrivacyNotice from "./components/utils/privacy-notice/PrivacyNotice";
import NotFound from "./components/utils/error-pages/NotFound";
import InternalServerError from "./components/utils/error-pages/InternalServerError";
//styles import
import './styles/globals.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<App/>}/>
            <Route path={"/projects"} element={<Projects/>}/>
            <Route path={"/contact"} element={<Contact/>}/>
            <Route path="/privacy-notice" element={<PrivacyNotice />} />
            <Route path="*" element={<NotFound />} status={404} />
            <Route path="/500" element={<InternalServerError status={500}/>}/>
        </Routes>
    </BrowserRouter>
);

reportWebVitals();
