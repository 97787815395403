import React from 'react';
import ContactForm from "../../components/main/contact/ContactForm";
import TypewriterEffect from "../../components/main/TypewriterEffect";
import stylesContact from "./contact.module.scss";
import {Helmet} from "react-helmet";
import Layout from "../../components/layout/Layout";

const Contact = () => {
    return (
        <Layout>
            {/* Helmet for metadata */}
            <Helmet>
                <title>Contactez Coralie Venuat - Développeuse Web Junior</title>
                <meta name="description" content="Vous souhaitez entrer en contact avec Coralie Venuat, développeuse web junior ? Remplissez le formulaire de contact pour toute demande d'information ou opportunité professionnelle." />
                <meta name="keywords" content="Contact Coralie Venuat, développeuse web, front-end, opportunités professionnelles" />
            </Helmet>

            {/*TypewriterEffect component*/}
            <TypewriterEffect text={"Contactez moi"}/>

            <section className={stylesContact["contact-page"]}>
                {/*Form*/}
                <ContactForm/>
            </section>
        </Layout>
    );
};

export default Contact;