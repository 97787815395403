import React, {useEffect, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import stylesProjects from "../../../pages/projects/projects.module.scss";
import ProjectsCardsTabOrDesktopVersion from "./responsive-cards/ProjectsCardsTabOrDesktopVersion";
import ProjectsCardsMobileVersion from "./responsive-cards/ProjectsCardsMobileVersion";

// Main component for displaying projects according to screen size
const ProjectsCards = ({filteredProjects}) => {
    const [isLoading, setIsLoading] = useState(true);

    // Define media query to use the component corresponding to the screen size in use
    const isMobile = useMediaQuery({
        query : '(max-width: 764px)'
    });

    const isTabletOrDesktop = useMediaQuery({
        query : '(min-width: 765px)'
    });

    // Simulates a loading delay before displaying projects
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
    return (
        <article >
            <ul className={stylesProjects["projects-container"]}>
                {isLoading ? (
                    // Generate 5 skeleton loaders
                    Array.from({ length: 5 }).map((item, index) => (
                        <li key={index} className={`${stylesProjects["project-card"]} skeleton`}>
                            <div className="skeleton-img"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-link"></div>
                        </li>
                    ))
                ) : (
                    // Render actual project cards in the use screen size
                    isTabletOrDesktop ? (
                        <ProjectsCardsTabOrDesktopVersion filteredProjects={filteredProjects} />
                    ) : isMobile ? (
                        <ProjectsCardsMobileVersion filteredProjects={filteredProjects} />
                    ) : null
                )}
            </ul>
        </article>
    );
};

export default ProjectsCards;
