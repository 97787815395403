import React, {Suspense} from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ScrollToTop from "../utils/scroll-element/ScrollToTop";
import {AnimatePresence, motion} from "framer-motion";
import {useLocation} from "react-router-dom";
import Loading from "../utils/loading-element/Loading";

const Layout = ({children}) => {
    const location = useLocation();
    return (
        <>
            <Header/>
            <AnimatePresence mode="wait">
                <motion.main
                    key={location.pathname}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    role={"main"}
                >
                    <Suspense fallback={<Loading/>}>
                        {children}
                    </Suspense>
                </motion.main>
            </AnimatePresence>

            <Footer/>
            <ScrollToTop/>
        </>
    )
};

export default Layout;